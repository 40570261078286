//colors

$color-dark: #112d32;
$color-white: #fff;
$light-green: #88bdbc;
$dark-green: #264e58;
$dark-text-color: #116466;
$main-text-color: rgba(51, 54, 68, 0.7);

//fonts sizes

$main-font: 18px;
$title-01: 28px;

// Screen
$desktop-xxl: 1600px;
$desktop-xl: 1440px;
$desktop-lg: 1320px;
$desktop-md: 1126px;
$tablet-md: 900px;
$tablet-sm: 768px;
$mobile-lg: 560px;
$mobile-md: 425px;
