@use './scss/variables';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
	color: variables.$main-text-color;
	font-size: variables.$main-font;
	font-family: 'Manrope', sans-serif;
	margin: 0;
	line-height: 1.37;
	padding-top: 117px;
}
a {
	text-decoration: none;
}
img {
	max-width: 100%;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
*,
::after,
::before {
	box-sizing: border-box;
}
.container {
	max-width: variables.$desktop-lg;
	margin: 0 auto;
	@media screen and (max-width: variables.$desktop-lg) {
		padding: 0 15px;
	}
}
// NavBar
.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 117px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: height 0.3s;
	background-color: variables.$color-white;
	z-index: 1050;
	.nav-container {
		width: calc(100% - 100px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media screen and (max-width: variables.$tablet-md) {
			width: calc(100% - 30px);
		}
		.logo {
			a {
				display: block;
				line-height: 0;
				height: 80px;
				transition: height 0.3s;
				@media screen and (max-width: variables.$tablet-md) {
					height: 60px;
				}
				img {
					height: 100%;
				}
			}
		}
		.mob-btn {
			display: none;
			@media screen and (max-width: variables.$tablet-md) {
				display: block;
			}
			button {
				position: relative;
				display: block;
				width: 50px;
				height: 50px;
				background-color: transparent;
				border: none;
				outline: none;

				span {
					position: absolute;
					width: 30px;
					height: 2px;
					background-color: variables.$color-dark;
					left: 10px;
					&:first-child {
						top: 12px;
						opacity: 1;
						transform: opacity 0.3s;
					}
					&:nth-child(2) {
						top: calc(50% - 1px);
						transform: rotate(0deg);
						transition: transform 0.3s 0.13s;
					}
					&:last-child {
						bottom: 12px;
						transform: rotate(0deg);
						transition: bottom 0.13s, transform 0.3s 0.13s;
					}
				}
				&.active {
					span {
						&:first-child {
							opacity: 0;
						}
						&:nth-child(2) {
							transform: rotate(45deg);
						}
						&:last-child {
							bottom: calc(50% - 1px);
							transform: rotate(-45deg);
						}
					}
				}
			}
		}
		.menu {
			display: flex;
			justify-content: space-between;
			align-items: center;
			column-gap: 146px;
			text-transform: uppercase;
			@media screen and (max-width: variables.$desktop-md) {
				column-gap: 30px;
			}

			@media screen and (max-width: variables.$tablet-md) {
				position: absolute;
				left: 0;
				top: 117px;
				transform: scaleY(0);
				width: 100%;
				transform-origin: top;
				transition: transform 0.4s;
				background-color: variables.$color-white;
				flex-direction: column;
				display: block;
				padding: 20px 10px 0;
			}
			&.active {
				transform: scaleY(1);
			}
			li {
				@media screen and (max-width: variables.$tablet-md) {
					padding: 30px 25px;
					border-bottom: 1px solid rgba(14, 21, 58, 0.1);
				}
				a {
					color: variables.$dark-text-color;
					font-weight: 800;
					@media screen and (max-width: variables.$tablet-sm) {
						font-size: 16px;
					}
				}
			}
		}
		.viber {
			@media screen and (max-width: variables.$tablet-md) {
				display: none;
			}
			a {
				display: flex;
				align-items: center;
				column-gap: 19px;
				color: variables.$dark-text-color;
				font-weight: 700;
				@media screen and (max-width: variables.$tablet-md) {
					font-size: 16px;
				}
			}
		}
	}
}
// Header
.header {
	.header-top {
		position: relative;
		line-height: 0;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: variables.$color-dark;
			opacity: 0.6;
		}
		video {
			width: 100%;
		}
		.header-text {
			text-align: center;
			position: absolute;
			left: 50%;
			top: 50%;
			line-height: 1.4;
			transform: translate(-50%, -50%);
			@media screen and (max-width: variables.$mobile-lg) {
				padding: 0 16px;
			}
			h1 {
				font-size: 7rem;
				color: variables.$color-white;
				margin: 0;
				@media screen and (max-width: variables.$desktop-xxl) {
					font-size: 5.5rem;
				}
				@media screen and (max-width: variables.$desktop-xl) {
					font-size: 4.5rem;
				}
				@media screen and (max-width: variables.$desktop-md) {
					font-size: 3.8rem;
				}
				@media screen and (max-width: variables.$mobile-lg) {
					font-size: 2.8rem;
				}
			}
			p {
				color: variables.$color-white;
			}
		}
	}
	.header-bottom {
		padding: 91px 0 77px;
		background-color: variables.$light-green;
		@media screen and (max-width: variables.$tablet-sm) {
			padding: 30px 0 30px;
		}
		.container {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			@media screen and (max-width: variables.$tablet-sm) {
				flex-wrap: wrap;
			}
			.header-item {
				max-width: 339px;
				@media screen and (max-width: variables.$tablet-sm) {
					width: 100%;
				}
				h2 {
					display: flex;
					column-gap: 16px;
					align-items: flex-start;
					color: variables.$dark-green;
					font-size: variables.$title-01;
					@media screen and (max-width: variables.$tablet-sm) {
						font-size: 24px;
					}
					img {
						@media screen and (max-width: variables.$tablet-sm) {
							width: 13%;
						}
					}
				}
				p {
					color: variables.$color-dark;
					@media screen and (max-width: variables.$tablet-sm) {
						font-size: 16px;
					}
				}
			}
		}
	}
}
// Top Life
.top-life {
	padding: 161px 0 104px;
	@media screen and (max-width: variables.$tablet-sm) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	h2 {
		font-size: 2.4rem;
		color: variables.$color-dark;
		text-align: center;
		@media screen and (max-width: variables.$tablet-sm) {
			font-size: 1.625rem;
		}
	}
	.content {
		display: flex;
		column-gap: 133px;
		font-size: 20px;
		line-height: 2rem;
		align-items: flex-end;
		@media screen and (max-width: variables.$tablet-md) {
			flex-direction: column;
			align-items: flex-start;
		}
		.image-content {
			line-height: 0;
			width: calc(50% - 66.5px);
			@media screen and (max-width: variables.$tablet-md) {
				width: calc(100% - 99px);
			}
			img {
				&:first-child {
					width: 90%;
				}
				&:last-child {
					position: relative;
					top: -35px;
					left: 99px;
				}
			}
		}
		.text-content {
			padding-bottom: 35px;
			width: calc(50% - 66.5px);
			@media screen and (max-width: variables.$tablet-md) {
				width: 100%;
				padding-bottom: 0;
			}
			p {
				margin: 0 0 30px;
				@media screen and (max-width: variables.$tablet-md) {
					font-size: 18px;
				}
			}
			blockquote {
				border-left: 2px solid variables.$color-dark;
				color: variables.$color-dark;
				font-weight: 700;
				margin: 40px 0 40px 0;
				padding-left: 1rem;
				font-size: 22px;
			}
			form {
				display: flex;
				column-gap: 30px;
				align-items: center;
				@media screen and (max-width: variables.$mobile-lg) {
					flex-direction: column;
					row-gap: 25px;
				}
				input {
					background: #ffffff;
					border: 1px solid #b4b4b4;
					box-sizing: border-box;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 15px;
					width: 354px;
					height: 58px;
					padding-left: 25px;
					@media screen and (max-width: variables.$mobile-lg) {
						width: 100%;
					}
				}
				button {
					background: variables.$dark-green;
					border: 1px solid rgba(0, 0, 0, 0.55);
					box-sizing: border-box;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 15px;
					width: 220px;
					height: 60px;
					color: #fff;
					font-weight: 500;
					font-size: 22px;
					line-height: 38px;
					letter-spacing: 3px;
					cursor: pointer;
				}
			}
		}
	}
}
// Top Success
.top-success {
	padding: 66px 0 61px;
	background-color: variables.$dark-green;
	color: variables.$color-white;
	@media screen and (max-width: variables.$tablet-md) {
		padding: 20px 0 20px;
	}
	.container {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		@media screen and (max-width: variables.$tablet-md) {
			flex-direction: column;
			align-items: flex-start;
		}
		.text-content {
			width: 45%;
			@media screen and (max-width: variables.$tablet-md) {
				width: 100%;
			}
			h2 {
				font-size: 2.4rem;
				@media screen and (max-width: variables.$tablet-md) {
					font-size: 1.75rem;
				}
			}
			h3 {
				@media screen and (max-width: variables.$tablet-md) {
					font-size: 1.75rem;
				}
			}
			p {
				@media screen and (max-width: variables.$tablet-md) {
					margin: 20px 0;
				}
			}
			li {
				font-size: 29px;
				font-weight: 600;
				display: flex;
				column-gap: 25px;
				align-items: center;
				line-height: 3rem;
				@media screen and (max-width: variables.$mobile-lg) {
					font-size: 24px;
					line-height: 1.1;
					align-items: flex-start;
					padding-bottom: 26px;
				}

				svg {
					@media screen and (max-width: variables.$mobile-lg) {
						position: relative;
						top: 3px;
					}
				}
			}
		}
		.image-content {
			width: 40%;
			position: relative;
			bottom: -61px;
			line-height: 0;
			@media screen and (max-width: variables.$tablet-md) {
				display: none;
			}
		}
	}
	.info {
		p {
			a {
				color: variables.$color-white;
				font-weight: 600;
				display: flex;
				column-gap: 10px;
				align-items: center;
				svg {
					width: 1.6rem;
					height: 1.6rem;
				}
			}
		}
		h3 {
			font-size: 1.8rem;
		}

		form {
			display: flex;
			column-gap: 30px;
			@media screen and (max-width: variables.$tablet-sm) {
				flex-direction: column;
				column-gap: 0;
				row-gap: 30px;
			}
			.input-group {
				input {
					width: 354px;
					height: 58px;
					background-color: variables.$color-white;
					border: 1px solid #b4b4b4;
					box-sizing: border-box;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 15px;
					padding-left: 25px;
					@media screen and (max-width: variables.$mobile-md) {
						width: 100%;
					}
				}
			}
			.btn-group {
				button {
					display: inline-block;
					width: 220px;
					height: 60px;
					background: variables.$color-white;
					border: 1px solid rgba(0, 0, 0, 0.55);
					box-sizing: border-box;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 15px;
					font-weight: 500;
					font-size: 22px;
					line-height: 38px;
					letter-spacing: 3px;
					cursor: pointer;
				}
			}
		}
	}
}
.success {
	color: green;
}
.error {
	color: red;
}
// Testemonials
.testemonials {
	padding: 90px 0 311px;
	@media screen and (max-width: variables.$tablet-md) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.testemonials-top {
		text-align: center;
		margin-bottom: 60px;
		h2 {
			font-size: 2.4rem;
			margin: 0 0 143px;
			color: variables.$dark-green;
			@media screen and (max-width: variables.$tablet-md) {
				margin-bottom: 60px;
				font-size: 1.625rem;
			}
		}
		h4 {
			font-size: 20px;
			margin: 52px 0 0;
			color: variables.$dark-green;
			@media screen and (max-width: variables.$tablet-md) {
				margin: 0;
				font-size: 18px;
			}
		}
	}
	.testemonials-bottom {
		display: flex;
		column-gap: 77px;
		@media screen and (max-width: variables.$tablet-md) {
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			row-gap: 30px;
			width: 100%;
			column-gap: 0;
		}
		.testemonial-item {
			display: flex;
			column-gap: 63px;
			align-items: center;
			padding: 58px 40px 49px;
			background: rgba(136, 189, 188, 0.3);
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			@media screen and (max-width: variables.$desktop-lg) {
				flex-wrap: wrap;
			}
			@media screen and (max-width: variables.$tablet-md) {
				justify-content: center;
				column-gap: 63px;
			}
			.text-content {
				max-width: 300px;
				text-align: center;

				h4 {
					font-size: 20px;
					color: variables.$dark-green;
					@media screen and (max-width: variables.$tablet-md) {
						font-size: 18px;
					}
				}
				p {
					color: variables.$dark-green;
					@media screen and (max-width: variables.$tablet-md) {
						font-size: 16px;
					}
				}
			}
			.media-content {
				@media screen and (max-width: variables.$tablet-md) {
					max-width: 70%;
				}
			}
		}
	}
}
.media-content {
	position: relative;

	span {
		background: rgba(245, 245, 245, 0.7);
		border-radius: 50%;
		width: 60px;
		margin-left: -30px;
	}
}
.play-btn {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	pointer-events: none;
}
// Contact
.contact {
	padding: 188px 0 109px;
	@media screen and (max-width: variables.$tablet-md) {
		padding: 40px 0 40px;
	}
	.container {
		display: flex;
		column-gap: 108px;
		align-items: center;
		@media screen and (max-width: variables.$tablet-md) {
			flex-direction: column;
			column-gap: 0;
			row-gap: 55px;
		}
		.contact-text {
			max-width: 422px;
			h2 {
				font-size: 56px;
				color: variables.$dark-green;
				margin-top: 0;
				@media screen and (max-width: variables.$tablet-md) {
					font-size: 2.2rem;
				}
			}
			li {
				display: flex;
				column-gap: 27px;
				align-items: center;
				row-gap: 12px;
				color: variables.$dark-green;
				a {
					color: variables.$dark-green;
				}
			}
		}
		.contact-form {
			padding: 89px 105px 71px 95px;
			background: rgba(132, 197, 197, 0.6);
			box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
			flex: 1;
			width: calc(100% - 638px);
			@media screen and (max-width: variables.$desktop-lg) {
				padding: 20px;
			}
			@media screen and (max-width: variables.$tablet-md) {
				width: 100%;
			}
			.form-group {
				display: flex;
				flex-direction: column;
				row-gap: 22px;
				margin-bottom: 22px;
				input {
					background: #ffffff;
					box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
					border-radius: 12px;
					width: 100%;
					height: 64px;
					border: none;
					padding-left: 35px;
				}
				textarea {
					background: #ffffff;
					box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
					border-radius: 12px;
					width: 100%;
					height: 268px;
					border: none;
					padding-left: 35px;
					padding-top: 35px;
				}
			}
			.btn-group {
				button {
					background: #ffffff;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 15px;
					color: variables.$dark-green;
					width: 245px;
					height: 76px;
					border: none;
					cursor: pointer;
					font-weight: 600;
					font-size: 1.65rem;
					letter-spacing: 2px;
					line-height: 38px;
					@media screen and (max-width: variables.$tablet-sm) {
						width: 100%;
					}
				}
			}
		}
	}
}

// YouTube Videos
.youtube-videos {
	background-color: variables.$light-green;
	padding: 102px 0 72px;
	color: variables.$dark-green;
	@media screen and (max-width: variables.$tablet-md) {
		padding: 40px 0 40px;
	}
	.title {
		text-align: center;
		margin-bottom: 117px;
		@media screen and (max-width: variables.$tablet-md) {
			margin-bottom: 40px;
		}
		h2 {
			font-size: 48px;
			color: variables.$dark-green;
			@media screen and (max-width: variables.$tablet-md) {
				font-size: 1.75rem;
				margin-top: 0;
			}
		}
	}
	.cards {
		display: flex;
		flex-direction: column;
		row-gap: 49px;
		li {
			display: flex;
			column-gap: 35px;
			padding: 25px 15px 25px 35px;
			background: rgba(255, 255, 255, 0.95);
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			align-items: flex-start;
			@media screen and (max-width: variables.$tablet-sm) {
				flex-direction: column;
				column-gap: 0;
				row-gap: 35px;
				padding: 15px;
			}
			.poster {
				position: relative;
				max-width: 252px;
				line-height: 0;
				border-radius: 10px;
				overflow: hidden;
				@media screen and (max-width: variables.$tablet-sm) {
					align-self: center;
				}
				span {
					background: rgba(245, 245, 245, 0.7);
					border-radius: 50%;
					width: 60px;
					margin-left: -30px;
				}
			}
			.yt-text-content {
				flex: 1;
				h2 {
					margin: 0;
					font-size: 24px;
					color: variables.$dark-green;
				}
				span {
					opacity: 0.8;
				}
				p {
					color: variables.$dark-green;
				}
			}
		}
	}
	.bottom {
		text-align: center;
		max-width: 544px;
		margin: 64px auto 0;
		button {
			width: 343px;
			height: 93px;
			background: #ffffff;
			border: 1px solid rgba(0, 0, 0, 0.55);
			box-sizing: border-box;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 15px;
			cursor: pointer;
			font-weight: 600;
			font-size: 1.65rem;
			letter-spacing: 2px;
			line-height: 38px;
			@media screen and (max-width: variables.$tablet-sm) {
				width: 100%;
			}
		}
	}
}

// Info popup
.info-popup {
	position: fixed;
	left: 0;
	top: 105vh;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.55);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1060;
	transition: top 0.3s;
	@media screen and (max-width: variables.$tablet-sm) {
		padding: 0 18px;
	}
	&.active {
		top: 0;
	}
	.close {
		position: absolute;
		background-color: variables.$light-green;
		border: none;
		outline: none;
		cursor: pointer;
		top: 45px;
		right: 45px;
		color: variables.$color-white;
		width: 45px;
		height: 45px;
		border-radius: 8px;
		font-size: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-color 0.3s;
		&:hover {
			background-color: variables.$dark-green;
		}
	}
	.card {
		background-color: variables.$color-white;
		text-align: center;
		border-radius: 15px;
		padding: 67px 180px 50px;
		max-width: 1056px;
		@media screen and (max-width: variables.$desktop-md) {
			padding: 67px 100px 50px;
		}
		@media screen and (max-width: variables.$tablet-sm) {
			padding: 20px 60px 20px;
		}
		@media screen and (max-width: variables.$mobile-lg) {
			padding: 20px;
		}
		h2 {
			margin: 0;
			line-height: 72px;
			color: variables.$dark-green;
		}
		.form {
			width: 100%;
			margin-top: 66px;
			button {
				display: inline-block;
				height: 76px;
				width: 100%;
				background: variables.$dark-green;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 15px;
				border: none;
				font-weight: 700;
				font-size: 24px;
				line-height: 38px;
				color: variables.$color-white;
				cursor: pointer;
				margin-top: 37px;
				max-width: 343px;
			}
			input {
				width: 100%;
				height: 58px;
				padding-left: 20px;
				border: 1px solid #b4b4b4;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 15px;
			}
		}
	}
}

// Footer
footer {
	padding: 64px 0 50px;
	.footer-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 17px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.5);
		@media screen and (max-width: variables.$tablet-md) {
			flex-direction: column;
			align-items: center;
			row-gap: 25px;
		}
		ul {
			display: flex;
			column-gap: 68px;
			@media screen and (max-width: variables.$desktop-md) {
				column-gap: 20px;
				flex-wrap: wrap;
			}
			@media screen and (max-width: variables.$mobile-lg) {
				column-gap: 0;
				row-gap: 20px;
			}
			li {
				@media screen and (max-width: variables.$mobile-lg) {
					width: 100%;
					text-align: center;
				}
				a {
					color: variables.$dark-text-color;
					font-size: 20px;
					text-transform: uppercase;
				}
			}
		}
		.logo {
			@media screen and (max-width: variables.$desktop-md) {
				width: 120px;
			}
		}
		.btn-group {
			a {
				display: inline-block;
				height: 76px;
				line-height: 76px;
				padding: 0 35px;
				background: variables.$dark-green;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 15px;
				border: none;
				font-size: 22px;
				color: variables.$color-white;
				cursor: pointer;
			}
		}
	}
	.footer-bottom {
		padding-top: 84px;
		text-align: center;
		.social {
			display: flex;
			font-size: 35px;
			column-gap: 35px;
			justify-content: center;
			@media screen and (max-width: variables.$mobile-md) {
				font-size: 28px;
				column-gap: 28px;
			}
			a {
				color: variables.$color-dark;
			}
		}
		.developed {
			a {
				color: variables.$color-dark;
			}
		}
	}
}
